var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticStyle:{"height":"100%","display":"flex","flex-direction":"column"}},[_c('div',{staticStyle:{"background":"#dbc7ff","height":"50px","display":"flex","align-items":"center"}},[_c('v-btn',{staticClass:"ml-3",attrs:{"icon":""},on:{"click":_vm.goBack}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)],1),_c('v-container',{staticStyle:{"flex-grow":"1","display":"flex","flex-direction":"column"},attrs:{"grid-list-xl":"","fluid":"","column":""}},[(_vm.firstMessage)?_c('v-card',{staticStyle:{"flex-grow":"0"},attrs:{"color":"blue"}},[_c('v-card-text',[_c('v-icon',{staticClass:"pa-2"},[_vm._v("mdi-information-outline")]),_vm._v(" Hey, it doesn't look like you two have spoken before! Say hi and solve his problem. ")],1)],1):_vm._e(),(_vm.conversation && _vm.user)?_c('v-card',[_c('v-card-title',{staticStyle:{"display":"flex","justify-content":"space-between"}},[(_vm.user.type == 'Organization')?_c('label',[_vm._v(" "+_vm._s(_vm.user.name)+" ")]):_c('label',[_vm._v(" "+_vm._s(_vm.user.first_name + (_vm.user.last_name ? " " + _vm.user.last_name : ""))+" ")]),_c('v-menu',{attrs:{"offset-y":"","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#482684"}},[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,false,2193112044)},[_c('v-list',_vm._l((_vm.menus),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"change":function($event){return _vm.onSelectedMenu(index)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),_c('v-card-text',[_vm._v(_vm._s(_vm.user.type))])],1):(_vm.conversation)?_c('v-card',[_c('v-card-title',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('label',[_vm._v(" Unknown user ")]),_c('v-menu',{attrs:{"offset-y":"","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#482684"}},[_vm._v(" mdi-dots-horizontal ")])],1)]}}])},[_c('v-list',_vm._l((_vm.menus),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"change":function($event){return _vm.onSelectedMenu(index)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)],1):_vm._e(),_c('div',{ref:"chatContent",staticStyle:{"height":"100%","padding-top":"20px","padding-bottom":"90px","flex-grow":"1","overflow-y":"scroll"},attrs:{"id":"chat-content"}},_vm._l((_vm.messages),function(msg,index){return _c('v-layout',{key:index,ref:index == _vm.messages.length - 1 ? 'lastMessage' : '',refInFor:true,staticClass:"mx-3",attrs:{"row":""}},[_c('v-flex',{attrs:{"xs6":"","sm4":"","offset-xs6":msg.sender === _vm.profile._id,"offset-sm8":msg.sender === _vm.profile._id}},[_c('div',{staticStyle:{"color":"gray","font-size":"12px","margin-bottom":"5px"}},[_vm._v(" "+_vm._s(_vm.formatDate(msg.date))+" ")]),_c('v-card',{staticClass:"msg text-block",attrs:{"color":msg.sender !== _vm.profile._id ? 'white' : 'blue'}},[_c('v-card-text',{class:msg.sender !== _vm.profile._id ? 'black--text' : 'white--text'},[_vm._v(" "+_vm._s(msg.text)+" ")])],1)],1)],1)}),1)],1),_c('v-footer',{attrs:{"absolute":""}},[_c('v-layout',{staticClass:"pa-4",attrs:{"row":"","align-center":""}},[_c('v-flex',{staticStyle:{"flex-grow":"1","max-width":"100%"}},[_c('v-textarea',{attrs:{"label":"Type= a message...","rows":"1","auto-grow":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendMessageButton()}},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"color":"blue","text":""},on:{"click":function($event){return _vm.sendMessageButton()}}},'v-btn',attrs,false),on),[_c('span',[_vm._v("send")]),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-send")])],1)]}}])},[_c('span',[_vm._v("Enter to send")])])],1)],1),_c('confirm-delete-dialog',{attrs:{"deleteDialog":_vm.deleteDialg,"onClose":_vm.onCloseConfirmDelete,"onConfirm":_vm.onConfirmDeleteConversation}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }